import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import GlobalHeader from 'components/Header';
import Footer from 'components/Footer';

import '../scss/style.scss';
import 'tachyons/css/tachyons.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const navItems = [
  {
    number: '01',
    label: 'Services',
    path: '/#services',
    subNavItems: [
      { label: 'OutCommunicate', path: '/strategic-communications' },
      { label: 'OutPosition', path: '/go-to-market-strategy' },
      { label: 'OutPerform', path: '/corporate-development' },
    ],
  },
  {
    number: '02',
    label: 'Successes',
    path: '/#successes',
    subNavItems: [],
  },
  {
    number: '03',
    label: 'About Us',
    path: '/#about',
    subNavItems: [],
  },
  {
    number: '04',
    label: 'Contact Us',
    path: '/#contact',
    subNavItems: [],
  },
  {
    number: '05',
    label: 'Insights',
    path: '/insights',
    subNavItems: [],
  },
];

const PageTemplate = ({ children, className, metaDescription }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={() => (
      <div className="main">
        <Helmet
          titleTemplate="%s | OutVox.com"
          htmlAttributes={{
            lang: 'en',
          }}
          meta={[
            { name: 'description', content: metaDescription || 'OutVox is a boutique management consultancy. CEOs and investors rely on our strategic guidance and operational support to differentiate, drive revenue, and scale their businesses.' },
          ]}
        />

        <GlobalHeader navItems={navItems} />

        <main className={className}>
          { children }
        </main>

        <Footer />
      </div>
    )}
  />
);

PageTemplate.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  className: PropTypes.string,
  metaDescription: PropTypes.string,
};

PageTemplate.defaultProps = {
  className: '',
  metaDescription: null,
};

export default PageTemplate;
