import React from 'react';
import PropTypes from 'prop-types';

const Arrow = ({ className, color, left }) => {
  if (left) {
    return (
      <svg
        className={className}
        width="23"
        height="13"
        viewBox="0 0 23 13"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          fill="none"
          fillRule="evenodd"
          stroke={color}
          strokeLinecap="round"
          transform="matrix(-1 0 0 1 22 0)"
        >
          <path d="M10.2235991,16.5106075 L10.2235991,-3.51513871" transform="matrix(0 1 1 0 3.724 -3.724)" />
          <polyline strokeLinejoin="round" points="11.792 3.208 18.273 9.59 24.59 3.369" transform="matrix(0 1 1 0 11.792 -11.792)" />
        </g>
      </svg>
    );
  }

  return (
    <svg
      className={className}
      width="22"
      height="13"
      viewBox="0 0 22 13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>arrow</title>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
      >
        <path d="M20.234 6.5H.208" />
        <path strokeLinejoin="round" d="M15 0l6.382 6.48-6.221 6.317" />
      </g>
    </svg>
  );
};

Arrow.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  left: PropTypes.bool,
};

Arrow.defaultProps = {
  className: '',
  color: '#9C773E',
  left: false,
};

export default Arrow;
