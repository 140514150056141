import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import Arrow from 'components/Arrow';

const Card = ({
  date,
  featured,
  linkText,
  summary,
  title,
  url,
}) => {
  const getFeaturedTag = () => {
    if (featured) {
      return (
        <div className="card--tag">
          Featured
        </div>
      );
    }

    return null;
  };

  const getCardDate = () => {
    if (date) {
      return (
        <h4 className="card--date">
          { date }
        </h4>
      );
    }

    return null;
  };

  return (
    <Link
      to={url}
      className={`card link ${featured ? 'card--featured' : ''}`}
    >
      {getFeaturedTag()}

      <div className="card--container">
        <div className="card--header">
          <div className="card--heading">
            {getCardDate()}

            <h3 className="card--title">
              { title }
            </h3>
          </div>

          <p className="card--summary">
            { summary }
          </p>
        </div>

        <div className="card--footer">
          <div className={`${featured ? 'btn' : ''}`}>
            { linkText }
            <Arrow className="mh2" />
          </div>
        </div>
      </div>
    </Link>
  );
};

Card.propTypes = {
  date: PropTypes.string,
  featured: PropTypes.bool,
  linkText: PropTypes.string,
  summary: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

Card.defaultProps = {
  date: null,
  featured: false,
  linkText: 'Read More',
};

export default Card;
