import React from 'react';
import PropTypes from 'prop-types';
import { Location } from '@reach/router';
import { CSSTransition } from 'react-transition-group';
import Sticky from 'react-stickynode';
import FocusLock from 'react-focus-lock';
import Link from 'components/Link';
import Logo from 'images/outvox-logo_gray.svg';
import { ReactComponent as Hamburger } from 'images/hamburger-icon.svg';
import { ReactComponent as Close } from 'images/close.svg';
import Drawer from './Drawer';
import Navigation from './Navigation';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { isExpanded } = this.state;
    this.setState(() => ({ isExpanded: !isExpanded }));
  }

  render() {
    const { navItems } = this.props;
    const { isExpanded } = this.state;
    const menuIcon = isExpanded ? <Close /> : <Hamburger />;

    return (
      <Sticky className="relative z-999">
        <FocusLock disabled={!isExpanded}>
          <header style={{ minHeight: '50px' }}>
            <nav className="navigation--global flex-row">
              <div className="pv3 relative">
                <div className="flex justify-between container">
                  <Link to="/" className="flex items-center">
                    <img src={Logo} width="123px" height="auto" alt="OutVox logo" />
                  </Link>

                  <Location>
                    {({ location }) => (
                      <Navigation location={location} navItems={navItems} />
                    )}
                  </Location>
                </div>

                <button
                  className="hamburger"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded={isExpanded}
                  aria-label="Toggle navigation"
                  onClick={this.handleClick}
                >
                  {menuIcon}
                </button>
              </div>
            </nav>

            <CSSTransition
              classNames="slide"
              in={isExpanded}
              timeout={1000}
            >
              <Drawer
                handleClick={this.handleClick}
                isExpanded={isExpanded}
                key="nav-drawer"
                navItems={navItems}
              />
            </CSSTransition>
          </header>
        </FocusLock>
      </Sticky>
    );
  }
}

Header.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      subNavItems: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
};

export default Header;
