export default [
  {
    id: 'corporate-development',
    url: '/corporate-development',
    title: 'OutPerform',
    summary: 'Corporate development and operational services',
  },
  // {
  //   id: 'outvox-network',
  //   url: '/outvox-network',
  //   title: 'OutExperience',
  //   summary: 'The OutVox network',
  // },
  {
    id: 'strategic-communications',
    url: '/strategic-communications',
    title: 'OutCommunicate',
    summary: 'Strategic communications',
  },
  {
    id: 'go-to-market-strategy',
    url: '/go-to-market-strategy',
    title: 'OutPosition',
    summary: 'Go-to-market strategic services',
  },
];
