import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import { Link as AnchorLink } from 'react-scroll';

class Link extends React.Component {
  constructor(props) {
    super(props);

    const { to } = this.props;

    this.state = {
      element: null,
      isInternal: false,
      link: to,
    };
  }

  componentDidMount() {
    this.init();
  }

  init() {
    const { to } = this.props;
    let link;

    if (to.substring(0, 1) === '#') {
      link = to.substring(1, to.length);

      this.setState({
        isInternal: true,
        link,
        element: document.getElementById(link),
      });
    }

    if (to.substring(0, 2) === '/#') {
      link = to.substring(2, to.length);

      this.setState({
        isInternal: true,
        link,
        element: document.getElementById(link),
      });
    }
  }

  render() {
    const { element, isInternal, link } = this.state;
    const {
      children,
      className,
      onClick,
      to,
    } = this.props;

    if (isInternal && element) {
      return (
        <AnchorLink
          className={className}
          to={link}
          smooth
          spy
          offset={50}
          duration={500}
          onClick={onClick}
        >
          {children}
        </AnchorLink>
      );
    }

    return (
      <GatsbyLink to={to} className={className} onClick={onClick}>
        {children}
      </GatsbyLink>
    );
  }
}

Link.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

Link.defaultProps = {
  className: '',
  onClick: () => {},
};

export default Link;
