import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import Arrow from 'components/Arrow';

const Navigation = ({ navItems }) => (
  <ul className="nav-list link mv0">
    {
      navItems.map((item, index) => (
        item.subNavItems.length !== 0 ? (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index} className="nav-item nav-subitems dn dn-m di-ns ph3 fw3 relative">
            <Link to={item.path}>
              {item.label}
              <svg className="pl1" width="9px" height="5px" viewBox="0 0 9 5" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <title>Rectangle</title>
                <desc>Created with Sketch.</desc>
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                  <g id="navigation" transform="translate(-932.000000, -58.000000)" stroke="#000000">
                    <g id="Stacked-Group" transform="translate(874.000000, 51.000000)">
                      <g id="Rectangle">
                        <polyline transform="translate(62.500000, 7.500000) rotate(45.000000) translate(-62.500000, -7.500000) " points="65 5 65 10 60 10" />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </Link>
            <div className="subnav absolute">
              {
                item.subNavItems.map((subItem, subIndex) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Link key={subIndex} to={subItem.path}>
                    <div className="flex justify-between items-center">
                      <div>{subItem.label}</div>
                      <Arrow className="pl2" color="#cfbda1" />
                    </div>
                  </Link>
                ))
              }
            </div>
          </li>
        ) : (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index} className="nav-item nav-singleitem dn dn-m di-ns ph3 fw3 relative">
            <Link to={item.path}>
              {item.label}
            </Link>
          </li>
        )
      ))
    }
  </ul>
);

Navigation.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      subNavItems: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
};

export default Navigation;
