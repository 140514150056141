import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import ScrollLock from 'react-scrolllock';

const Drawer = ({ handleClick, isExpanded, navItems }) => (
  <ScrollLock accountForScrollbars={false} isActive={isExpanded}>
    <div className="navigation--global_mobile" id="navbarSupportedContent">
      <ul className="flex flex-column justify-center relative mt0 pt4 ph4 list">
        {
          navItems.map(({
            label,
            number,
            path,
            subNavItems,
          }, index) => (
            subNavItems.length !== 0 ? (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index} className="nav-item w-70 center link f2">
                <div className="flex items-center">
                  <p className="mr3 f4 fw3 item--number">
                    {number}
                  </p>

                  <Link className="item w-100 link black" to={path} onClick={handleClick}>
                    {label}
                  </Link>
                </div>

                {
                  subNavItems.map((subItem, subIndex) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={subIndex} className="subnav">
                      <Link to={subItem.path}>
                        {subItem.label}
                      </Link>
                    </div>
                  ))
                }
              </li>
            ) : (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index} className="nav-item flex items-center w-70 center link f2">
                <p className="mr3 f4 fw3 item--number">
                  {number}
                </p>

                <Link className="item w-100 link black" to={path} onClick={handleClick}>
                  {label}
                </Link>
              </li>
            )
          ))
        }
      </ul>
    </div>
  </ScrollLock>
);

Drawer.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Drawer;
