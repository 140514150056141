import React from 'react';
import Link from 'components/Link';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import Logo from 'images/outvox-logo_white.svg';
import LinkedIn from 'images/linkedin-in.png';
import Envelope from 'images/envelope-icon.png';
import PointPin from 'images/pointpin.png';

class Footer extends React.Component {
  static getCurrentYear() {
    return (new Date()).getFullYear();
  }

  render() {
    return (
      <footer className="footer">
        <div className="container w-100">

          <div className="logo__space w-100 w-100-m w-25-ns">
            <Link to="/" className="footer__logo">
              <img src={Logo} alt="Outvox-logo-white" />
            </Link>
          </div>

          <nav className="footer__nav w-100 w-100-m w-75-ns ml0 ml0-m ml5-ns">
            <div className="w-100 w-third-l">
              <h3>Services</h3>
              <ul className="footer__nav--primary list">
                <li>
                  <Link to="/strategic-communications">
                    OutCommunicate
                  </Link>
                </li>
                <li>
                  <Link to="/go-to-market-strategy">
                    OutPosition
                  </Link>
                </li>
                <li>
                  <Link to="/corporate-development">
                    OutPerform
                  </Link>
                </li>
              </ul>
            </div>

            <div className="w-100 w-third-l">
              <ul className="footer__nav--secondary list">
                <li>
                  <h3><Link to="/#successes">Successes</Link></h3>
                </li>

                <li>
                  <h3><Link to="/#about">About Us</Link></h3>
                </li>

                <li>
                  <h3><Link to="/insights">Insights</Link></h3>
                </li>
              </ul>
            </div>

            <div className="contact__list w-100 w-third-l">
              <h3>Contact Us</h3>

              <ul className="list">
                <li>
                  <img src={LinkedIn} alt="LinkedIn icon" />

                  <div>
                    <OutboundLink href="https://www.linkedin.com/company/outvox/">
                      Connect on LinkedIn
                    </OutboundLink>
                  </div>
                </li>

                <li>
                  <img src={Envelope} alt="Envelope icon" />

                  <div>
                    <a href="mailto:consultation@outvox.com">
                      consultation@outvox.com
                    </a>
                  </div>
                </li>

                <li className="location__list">
                  <img src={PointPin} alt="Map pin icon" />

                  <div>
                    <div className="location__list--item">
                      <a href="https://www.google.com/maps/place/1300+19th+St+NW,+Washington,+DC+20036/@38.9074548,-77.0461768,17z/data=!3m1!4b1!4m5!3m4!1s0x89b7b7c7f6b725ed:0x1aa41b8812f86968!8m2!3d38.9074548!4d-77.0439881" target="_blank" rel="noopener noreferrer" title="Link to Washington, DC location on Google Maps">Washington, DC</a>
                      <br />
                      (202) 379 3085
                    </div>

                    <div className="location__list--item">
                      <a href="https://www.google.com/maps/place/222+W+Merchandise+Mart+Plaza,+Chicago,+IL+60654/@41.8884096,-87.6376385,17z/data=!3m1!4b1!4m5!3m4!1s0x880e2cb6fa650467:0xd94994faa9dd5715!8m2!3d41.8884096!4d-87.6354498" target="_blank" rel="noopener noreferrer" title="Link to Chicago, IL location on Google Maps">Chicago, IL</a>
                      <br />
                      (847) 421 1477
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <div className="footer__copyright">
          <div className="container">
            Copyright &copy;
            {`${Footer.getCurrentYear()} OutVox. All Rights Reserved.`}
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
